import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

function Whitelist() {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [inputValues, setInputValues] = useState({
    email: "",
    twitter: "",
    discord: "",
    walletAddress: "",
  });
  const apiKey = process.env.BASE_URL;
  console.log(apiKey)

  const [confirm, setConfirm] = useState(false);
  const handleChange = (evt) => {
    const value = evt.target.value;
    setInputValues({
      ...inputValues,
      [evt.target.name]: value,
    });
  };
  const handleSubmit = () => {
    if (inputValues.walletAddress) {
      if (inputValues.email) {
        if (inputValues.email.match(mailformat)) {
          if (inputValues.discord || inputValues.twitter) {
            axios
              .post(
                `https://api-okx.katanainu.com/api/v1/whitelist/create`,
                inputValues,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                  },
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  setConfirm(true);
                  toast.success("Thanks for your submit.");
                  // toast.success(
                  //   "Just a limited amount of people will be picked and added to the whitelist. You can check it on 15th September on this Page."
                  // );
                }
                // handleClose();
              })
              .catch((error) => {
                toast.error(error?.response?.data?.data?.message);
              });
          } else {
            toast.error("Add one of the social handler");
          }
        } else {
          toast.error("You have entered an invalid email address!");
        }
      } else {
        toast.error("Add email");
      }
    } else {
      toast.error("input wallet address");
    }
  };

  return (
    <section className="whitelist-area ">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8 col-9  col-md-8 col-sm-11">
            <div className="modal fade " id="myModal">
              <div className="modal-dialog">
                <div className="modal-content" style={{ minWidth: "300px" }}>
                  <div
                    className="modal-header"
                    style={{ border: "1px solid " }}
                  >
                    <a
                      href=" #"
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                    >
                      &times;
                    </a>
                  </div>
                  <div className="form_wrapper">
                    <div className="form_container">
                      <div className="title_container">
                        <h2>GET WHITELISTED NOW!</h2>
                        <p>
                          you need to provide email address and your social
                          media to be whitelisted.
                        </p>
                      </div>
                      <div className="row clearfix">
                        <div className="">
                            <div className="input_field">
                              {" "}
                              <span>
                                <i
                                  aria-hidden="true"
                                  className="fa fa-envelope"
                                ></i>
                              </span>
                              <input
                               onChange = {handleChange}
                                type="email"
                                name="email"
                                placeholder="Email"
                                required
                              />
                            </div>
                            <div className="input_field">
                             
                              <input
                               onChange = {handleChange}
                                type="text"
                                name="walletAddress"
                                placeholder="Wallet Address"
                                required
                              />
                            </div>
                            <div className="row clearfix">
                              <div className="col_half">
                                <div className="input_field">
                                  {" "}
                                  <span>
                                    <i className="fa-brands fa-twitter"></i>
                                  </span>
                                  <input
                                   onChange = {handleChange}
                                    type="text"
                                    name="twitter"
                                    placeholder="Twitter"
                                  />
                                </div>
                              </div>
                              <div className="col_half">
                                <div className="input_field">
                                  {" "}
                                  <span>
                                    <i className="fa-brands fa-discord"></i>
                                  </span>
                                  <input
                                   onChange = {handleChange}
                                    type="text"
                                    name="discord"
                                    placeholder="Discord"
                                    required
                                  />
                                </div>
                              </div>
                              <p className="white">
                                Just a limited amount of people will be picked
                                and added to the whitelist.
                              </p>

                              <p className="white">
                                You will be notified via e-mail.
                              </p>
                            </div>
                            <input
                            onClick={handleSubmit}
                              className="button"
                              type="submit"
                              value="Submit"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Whitelist;
