import React from "react";

const switchChain = async () => {
  try {
    const ethereum = window.ethereum;

    if (!ethereum) {
      console.log("MetaMask is not installed.");
      return;
    }

    // Attempt to switch the chain
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0xC4" }],
    });
  } catch (switchError) {
    // Handle the case where the chain has not been added to MetaMask
    if (switchError.code === 4902) {
      try {
        // Try adding the new chain to MetaMask
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0xc4",
              rpcUrls: ["https://xlayerrpc.okx.com"],
              chainName: "XLayer Testnet",
              nativeCurrency: {
                name: "OKB",
                symbol: "OKB",
                decimals: 18,
              },
              blockExplorerUrls: ["https://xlayerrpc.okx.com"],
            },
          ],
        });
      } catch (addChainError) {
        // Handle any errors during the chain addition process
        console.log("Error Adding Chain: ", addChainError);
      }
    } else if (switchError.code === 4001) {
      // Handle the case where the user rejects the request
      console.log("User rejected the request to switch chains.");
    } else {
      // Handle other errors
      console.log("Error switching chain: ", switchError);
    }
  }
};

export default switchChain;
