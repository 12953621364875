import Routers from "./layouts/Routers";
import { Route } from "react-router-dom";
import Home from "./pages/home";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const projectId = "8ec0c378d24abbf93366b8f866a41d6c";
const queryClient = new QueryClient();

// 2. Set chains
const X1Testnet = {
  chainId: 195,
  name: "XLayer Testnet",
  currency: "OKB",
  explorerUrl: "https://www.oklink.com/x1-test",
  rpcUrl: "https://testrpc.x1.tech",
};

// 3. Create modal
const metadata = {
  name: "XLAYER-KATANAINU",
  description: "XLAYER-KATANAINU",
  url: "https://mywebsite.com",
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [X1Testnet],
  projectId,
});

/*  =========== pages ======== */
/*  ===========End pages ======== */
function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <Routers>
          <Route path="/" element={<Home />} />
        </Routers>
      </QueryClientProvider>
    </>
  );
}

export default App;
