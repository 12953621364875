import React from "react";

function Brand() {
  return (
    <section className="nft-area">
      <div className="custom-container">
        <div className="row">
          <div className="col-md-12 justify-content-center">
            <ul className="nft-details m-0">
              <li
                className="wow fadeInLeft"
                data-wow-delay="0.3s"
                data-wow-duration="1s"
              >
                <span>CHAIN:</span>
                Xlayer
              </li>

              <div className="brand-div"></div>
              <li
                className="wow fadeInLeft"
                data-wow-delay="0.4s"
                data-wow-duration="1s"
              >
                <span>Supply:</span>
                20,000
              </li>
              <div className="brand-div"></div>

              <li
                className="wow fadeInLeft"
                data-wow-delay="0.5s"
                data-wow-duration="1s"
              >
                <span>lootbox:</span> 4 character & 5 Swords
              </li>
              <div className="brand-div"></div>

              <li
                className="wow fadeInLeft"
                data-wow-delay="0.6s"
                data-wow-duration="1s"
              >
                <span>Price:</span>
                0.01$/Mint
              </li>
              <div className="brand-div"></div>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Brand;
