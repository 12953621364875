import React from "react";

const Modal = () => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h1 style={{ color: "white", fontSize: "20px", textAlign: "center" }}>
          Please wait while we confirm your transaction.
        </h1>
        <div
          className="spinner"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          <i
            className="fa fa-spinner fa-spin"
            style={{ color: "green", fontSize: "30px" }}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default Modal;
