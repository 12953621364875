import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { endPoint, queryKeys } from "./queryConstant";
import { ToastContainer, toast } from "react-toastify";
export const useMutateAddTxHash = () => {
  const queryClient = useQueryClient();

  const queryKey = [queryKeys.getLootBoxData];

  const mutationFn = async (data) => {
    const config = {
      method: "POST",
      url: `${endPoint}/addTransactionHash`,
      data:data,
    };

    const tx = await axios.request(config);
    return tx?.data;
  };

  return useMutation({
    mutationFn,
    onError: (res) => {
      toast(res.message);
      queryClient.invalidateQueries(queryKey);
      setTimeout(() => queryClient.invalidateQueries(queryKey), 1000);
    },
    onSuccess: (res) => {
      toast(res.message);
      queryClient.invalidateQueries(queryKey);
      setTimeout(() => queryClient.invalidateQueries(queryKey), 1000);
    },
    onMutate: (res) => {
      queryClient.invalidateQueries(queryKey);
      setTimeout(() => queryClient.invalidateQueries(queryKey), 1000);
    },
  });
};
export const useMutateDeleteUser = () => {
  const queryClient = useQueryClient();

  const queryKey = [queryKeys.deleteUser];

  const mutationFn = async (data) => {
    const config = {
      method: "POST",
      url: `${endPoint}/deleteUser`,
      data:data,
    };

    const tx = await axios.request(config);
    return tx?.data;
  };

  return useMutation({
    mutationFn,
    onError: (res) => {
      queryClient.invalidateQueries(queryKey);
      setTimeout(() => queryClient.invalidateQueries(queryKey), 1000);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(queryKey);
      setTimeout(() => queryClient.invalidateQueries(queryKey), 1000);
    },
    onMutate: (res) => {
      queryClient.invalidateQueries(queryKey);
      setTimeout(() => queryClient.invalidateQueries(queryKey), 1000);
    },
  });
};
